import revive_payload_client_upOU7eOQPU from "/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_rollup@4.21.0_sass@1.77.8_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_1ekxQYkJAZ from "/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_rollup@4.21.0_sass@1.77.8_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GBocwtJj9c from "/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_rollup@4.21.0_sass@1.77.8_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_z5UUMZLf17 from "/app/node_modules/.pnpm/nuxt-site-config@2.2.15_rollup@4.21.0_vite@5.4.2_vue@3.4.38/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import navigation_repaint_client_Xevrf6Mnox from "/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_rollup@4.21.0_sass@1.77.8_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_rgwpF1dhta from "/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_rollup@4.21.0_sass@1.77.8_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ekIoNTlxCU from "/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_rollup@4.21.0_sass@1.77.8_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_sJYZZOHTbO from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.4_rollup@4.21.0_typescript@5.5.4_vue@3.4.38/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_J9ZbqaigXX from "/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_rollup@4.21.0_sass@1.77.8_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_Mp9QcMIJTS from "/app/node_modules/.pnpm/nuxt-delay-hydration@1.3.6_rollup@4.21.0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import switch_locale_path_ssr_6IKRt6P7t0 from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.0_rollup@4.21.0_vue@3.4.38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_pXl0JHqcFO from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.0_rollup@4.21.0_vue@3.4.38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_Qa1h2am6Do from "/app/node_modules/.pnpm/@storyblok+nuxt@6.0.10/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import motion_7Tpjbwj7fx from "/app/node_modules/.pnpm/@vueuse+motion@2.2.3_rollup@4.21.0_vue@3.4.38/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import plugin_uNYPflKUpC from "/app/node_modules/.pnpm/@nuxtjs+device@3.1.1_rollup@4.21.0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import clickOutsideDirective_JuSoe6vGeC from "/app/plugins/clickOutsideDirective.ts";
import error_handler_kEP5FliEXj from "/app/plugins/error-handler.ts";
import fetchGlobalData_EOfhKKMol6 from "/app/plugins/fetchGlobalData.ts";
import lottie_client_MJBevkcINk from "/app/plugins/lottie.client.ts";
import onLanguageSwitch_Zq3lupusSn from "/app/plugins/onLanguageSwitch.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
export default [
  revive_payload_client_upOU7eOQPU,
  unhead_1ekxQYkJAZ,
  router_GBocwtJj9c,
  _0_siteConfig_z5UUMZLf17,
  navigation_repaint_client_Xevrf6Mnox,
  check_outdated_build_client_rgwpF1dhta,
  chunk_reload_client_ekIoNTlxCU,
  plugin_vue3_sJYZZOHTbO,
  components_plugin_KR1HBZs4kY,
  prefetch_client_J9ZbqaigXX,
  nuxt_plugin_Mp9QcMIJTS,
  switch_locale_path_ssr_6IKRt6P7t0,
  i18n_pXl0JHqcFO,
  plugin_Qa1h2am6Do,
  motion_7Tpjbwj7fx,
  plugin_uNYPflKUpC,
  clickOutsideDirective_JuSoe6vGeC,
  error_handler_kEP5FliEXj,
  fetchGlobalData_EOfhKKMol6,
  lottie_client_MJBevkcINk,
  onLanguageSwitch_Zq3lupusSn,
  sentry_client_shVUlIjFLk
]